import { KEY_LOCAL_STORAGE } from '@type/customType';
import { encryptText, isServer } from '@utils/helper';
import { getItem, setItem } from '@utils/localStorage';
import { useCallback, useMemo, useState } from 'react';
import { I_Project, I_ProtectPasswordConfig } from '../types';

interface Props {
  detailProject?: I_Project;
  configProtectPassword?: I_ProtectPasswordConfig;
}

export const useCheckPasswordTour = ({
  detailProject,
  configProtectPassword,
}: Props) => {
  const [isPasswordCorrectly, setIsPasswordCorrectly] = useState(false);

  const listProjectGuardOpened = useMemo(
    () =>
      isServer
        ? []
        : JSON.parse(
            getItem(KEY_LOCAL_STORAGE.LIST_PROJECT_PASS_GUARD) || '[]',
          ),
    [],
  );

  const isOpenGuardPassword = useMemo(() => {
    if (isServer) return false;
    if (configProtectPassword?.isEnabled && configProtectPassword?.password) {
      const previousPassword = getItem(KEY_LOCAL_STORAGE.PASSWORD_TOUR);
      const currentPassword = encryptText(configProtectPassword?.password);

      if (!configProtectPassword?.isAlwaysAsk) {
        if (
          isPasswordCorrectly ||
          (listProjectGuardOpened.indexOf(encryptText(detailProject?.id)) >
            -1 &&
            previousPassword === currentPassword)
        )
          return false;
        return true;
      }
      return !isPasswordCorrectly;
    }

    return false;
  }, [
    detailProject,
    isPasswordCorrectly,
    configProtectPassword,
    listProjectGuardOpened,
  ]);

  const onCheckGuardPassword = useCallback(() => {
    setIsPasswordCorrectly(true);
    if (!configProtectPassword?.isAlwaysAsk) {
      setItem(
        KEY_LOCAL_STORAGE.PASSWORD_TOUR,
        encryptText(configProtectPassword?.password),
      );
      const projectId = encryptText(detailProject?.id);
      if (!listProjectGuardOpened.length)
        setItem(
          KEY_LOCAL_STORAGE.LIST_PROJECT_PASS_GUARD,
          JSON.stringify([projectId]),
        );
      else
        setItem(
          KEY_LOCAL_STORAGE.LIST_PROJECT_PASS_GUARD,
          JSON.stringify(
            Array.from(new Set([...listProjectGuardOpened, projectId])),
          ),
        );
    }
  }, [configProtectPassword, detailProject, listProjectGuardOpened]);

  return useMemo(
    () => ({
      isPasswordCorrectly,
      setIsPasswordCorrectly,
      isOpenGuardPassword,
      onCheckGuardPassword,
    }),
    [isOpenGuardPassword, isPasswordCorrectly, onCheckGuardPassword],
  );
};
