import { isServer } from '@utils/helper';
import { openNotificationWithIcon } from './helper';

function storageAvailable() {
  const test = 'test';
  try {
    if (isServer) return true;
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

const getItem = (key: string) => {
  const isLocalStorageAvailable = storageAvailable();
  if (isLocalStorageAvailable) {
    return localStorage.getItem(key);
  }
  openNotificationWithIcon(
    'error',
    'Error',
    'Cookies/Storages is not available. Please allow Cookies/Storages on this site so that it can work properly.',
    10,
    'localstorage',
  );
  return '';
};

const setItem = <T extends string>(key: T, value: T) => {
  const isLocalStorageAvailable = storageAvailable();
  if (isLocalStorageAvailable) {
    if (!!key && !!value) return localStorage.setItem(key, value);
  }
  openNotificationWithIcon(
    'error',
    'Error',
    'Cookies/Storages is not available. Please allow Cookies/Storages on this site so that it can work properly.',
    10,
    'localstorage',
  );
  return '';
};

const removeItem = <T extends string>(key: T) => localStorage.removeItem(key);

const clearAllLS = () => {
  localStorage.clear();
};

export { storageAvailable, getItem, setItem, removeItem, clearAllLS };
